var slider;

slider = function() {
  var Swiper, reviewsSlider, sertifikatesSlider;
  Swiper = require('swiper');
  reviewsSlider = new Swiper('.swiper-container#reviews-slider', {
    slidesPerView: 3,
    centeredSlides: true,
    autoplay: 3000,
    loop: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    pagination: '.swiper-pagination',
    paginationType: 'fraction',
    slideActiveClass: 'reviews__slide--active',
    breakpoints: {
      480: {
        slidesPerView: 1
      }
    },
    paginationFractionRender: function(swiper, currentClassName, totalClassName) {
      return '<span class="' + currentClassName + '"></span>' + '  из  ' + '<span class="' + totalClassName + '"></span>';
    }
  });
  return sertifikatesSlider = new Swiper('.swiper-container#sertifikates-slider', {
    slidesPerView: 4,
    autoplay: 4000,
    loop: true,
    spaceBetween: 75,
    breakpoints: {
      1023: {
        slidesPerView: 3
      },
      767: {
        slidesPerView: 2
      },
      480: {
        slidesPerView: 1
      }
    }
  });
};

module.exports = slider;
