var tab;

tab = function() {
  var $, module, moduleName;
  $ = require('jquery');
  moduleName = 'tab';
  module = $("." + moduleName);
  return module.each(function() {
    var firstEnabled, thisContents, thisMod, thisTabs;
    thisMod = $(this);
    thisTabs = thisMod.find('[data-tab]');
    thisContents = thisMod.find('[data-content]');
    firstEnabled = function(qSelector) {
      return qSelector.each(function(i) {
        if (i === 0) {
          $(this).addClass('enabled');
        }
        return i++;
      });
    };
    firstEnabled(thisTabs);
    firstEnabled(thisContents);
    return thisTabs.click(function() {
      var thisData;
      thisData = $(this).data('tab');
      $(this).addClass('enabled');
      $(this).siblings().removeClass('enabled');
      return thisContents.each(function() {
        var thisCont, thisContData;
        thisCont = $(this);
        thisContData = thisCont.data('content');
        if (thisContData === thisData) {
          thisCont.addClass('enabled');
          return thisCont.siblings().removeClass('enabled');
        }
      });
    });
  });
};

module.exports = tab;
