var header;

header = function() {
  var $, anchors, moduleName, stickOffset, sticker, stickerOffsetStatic;
  $ = require("jquery");
  moduleName = 'header';
  sticker = $("." + moduleName + "__sticker");
  stickOffset = $("." + moduleName + "__sticker-offset");
  stickerOffsetStatic = sticker.offset().top;
  console.log(stickerOffsetStatic);
  $(window).scroll(function() {
    if ($(window).scrollTop() > stickerOffsetStatic) {
      sticker.addClass(moduleName + "__sticker--sticky");
      return stickOffset.addClass(moduleName + "__sticker-offset--active");
    } else {
      sticker.removeClass(moduleName + "__sticker--sticky");
      return stickOffset.removeClass(moduleName + "__sticker-offset--active");
    }
  });
  anchors = $(".header__item a");
  return anchors.click(function(e) {
    var id, top;
    e.preventDefault();
    id = $(this).attr('href');
    top = $(id).offset().top - 30;
    return $('body').animate({
      scrollTop: top
    }, 500);
  });
};

module.exports = header;
