var $, callmodule, header, jform, slider, tab;

$ = require('jquery');

slider = require('../_modules/components/slider/slider');

header = require('../_modules/components/header/header');

callmodule = require('./functions/callmodule');

jform = require('../_modules/components/jform/jform');

tab = require('../_modules/components/tab/tab');

$(document).ready(function() {
  header();
  slider();
  callmodule();
  jform();
  return tab();
});
